@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;600;700&display=swap");
@import "react-toastify/dist/ReactToastify.css";
// @import "react-datepicker/dist/react-datepicker.css";

*,
*::after,
*::before {
  box-sizing: border-box;
}

* {
  margin: 0;
  padding: 0;
}

:root {
  --theme-bg-color: #181818;
}

body {
  font-family: "Roboto" !important;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  background-color: var(--theme-bg-color);
}

/* width */
::-webkit-scrollbar {
  width: 6px !important;
  height: 6px !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: #131313;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.section-small::-webkit-scrollbar {
  width: 3px !important;
}

.css-1uccc91-singleValue {
  color: white !important;
}

select option {
  background: #090909 !important;
  color: #fff;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
  border: 0 !important;
}

option {
  background-color: #0f0f0f;
  color: #a3a3a3;
}

input[type="number"] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.toastBody {
  font-size: 0.875rem !important;
}

table {
  letter-spacing: 1px;
  font-weight: 500;
  font-size: 12px;
  thead th {
    border-bottom: 0px !important;
    line-height: 120%;
    color: #ffff !important;
    padding: 6px 4px !important;
  }
  tbody {
    tr {
      vertical-align: middle;
    }
    td {
      padding: 6px 4px !important;
      color: #d1d1d1;
    }
  }
}

// Basic Css
.text-center {
  text-align: center !important;
}

.w-100 {
  width: 100% !important;
}
// Basic Css

#chartdiv canvas:nth-of-type(2) {
  // display:none !important
}

.group-account-dd input, .group-account-dd > div > div > div {
  color: rgb(255, 255, 255);
}